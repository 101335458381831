



























import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"

@Component({})
export default class HousePriceHistory extends Vue {
  @Prop({ required: true }) house!: House
}
